
import {
  DialogActions,
} from '@mui/material';

import { styled } from '@mui/system';

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.common.navy,
}));

export default StyledDialogActions;