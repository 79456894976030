import { StateCreator } from 'zustand'

import { OptionTemplate, Seller, SellerCodeOverride } from '../interfaces'

export interface SellerState {
  seller: Seller;
  sellerCode: string;
  sellerTemplate: OptionTemplate;
  updateSeller: (seller: Seller, sellerCode: string, template: OptionTemplate) => void;
}

export const createSellerSlice: StateCreator<
  SellerState,
  [],
  [],
  SellerState  
> = (set, get) => ({
  seller: {} as Seller,
  sellerCode: {} as string,
  sellerTemplate: {} as OptionTemplate,
  updateSeller: (seller: Seller, sellerCode: string, template: OptionTemplate) => {

    const upperSellerCode = sellerCode ? sellerCode.toUpperCase() : sellerCode;

    const newState = {
      seller: seller,
      sellerCode: upperSellerCode,
      sellerTemplate: template,
    }

    if (upperSellerCode && Object.keys(SellerCodeOverride).includes(upperSellerCode)) {
      // For when JiraProjectId is not the same as the state code
      newState.sellerCode = SellerCodeOverride[upperSellerCode as keyof typeof SellerCodeOverride];
    } 

    set(() => (newState));
  },
})
