import { ExpandMore } from "@mui/icons-material";
import { Button, ButtonProps, Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";

import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { useNavigate } from "react-router-dom";
import { shallow } from 'zustand/shallow';
import { useGlobalStore } from "../../store";
import { BuyerSelect } from "./BuyerSelect";

interface CommitteeNavBarProps {
  logoutWithRedirect: () => void;
}

const headerButtonProps: ButtonProps = {
  variant: "text",
  sx: { color: "white" }
}

export const CommitteeNavBar = (props: CommitteeNavBarProps) => {
  const navigate = useNavigate();
  
  const { logoutWithRedirect } = props;
  const { buyer, person, buyerPersons } = useGlobalStore(
    (state) => ({ 
      buyer: state.buyer, person: state.person, buyerPersons: state.buyerPersons
    }),
    shallow
  );

  const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-menu' })
  const newCommitteeNav = () => {
    popupState.close();
    navigate(`./new`);
  };

  return (
    <React.Fragment>
      {person && <Grid item sm={4} display={'block'}>
        <Button
          {...bindTrigger(popupState)}
          {...headerButtonProps}
        >
          <Typography variant={'subtitle2'}>
            Committees
          </Typography>
          <ExpandMore />
        </Button>
        <Menu {...bindMenu(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          elevation={1}
        >
          <BuyerSelect
            buyerPersons={buyerPersons}
            currentBuyer={buyer}
            personId={person.id}
            handleClose={popupState.close}
          />
          <Divider />
          <MenuItem onClick={newCommitteeNav}>
            <Typography variant={'body2'}>
              Create a new committee
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>}
      {person && <Grid item sm={4} display={'block'}>
        <Button
          {...headerButtonProps}
          onClick={() => navigate(`./user`)}
        >
          <Typography variant={'subtitle2'}>
            My profile
          </Typography>
        </Button>
      </Grid>}
      {!person && <Grid item sm={8} display={'block'} />}
      <Grid item sm={4} display={'block'}>
        <Button
          {...headerButtonProps}
          onClick={() => logoutWithRedirect()}
        >
          <Typography variant={'subtitle2'}>
            Log out
          </Typography>
        </Button>
      </Grid>
    </React.Fragment>
  )

}