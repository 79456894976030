import { BuyerPerson, Permission } from "../interfaces"

export const hasPermission = (
  buyerPersons: BuyerPerson[],
  buyerId: number,
  permissionToCheck: Permission
) => {
  const permissibleBuyerPerson = buyerPersons.find(buyerPerson => {
    return buyerPerson.buyer.id === buyerId && (
      buyerPerson.permissionGroup.isSuperuser || 
      buyerPerson.permissionGroup.permissions.includes(permissionToCheck)
    );
  })
  return permissibleBuyerPerson !== undefined;
}

export const hasAnyPermission = (
  buyerPersons: BuyerPerson[],
  buyerId: number,
  permissionsToCheck: Permission[]
) => {
  const allowedPermissions = permissionsToCheck.filter(permission => {
    return hasPermission(buyerPersons, buyerId, permission)
  });
  return allowedPermissions.length > 0;
}