import useAxios from 'axios-hooks';
import { User } from '@auth0/auth0-react';

export function useLambdas(functionName: string, user: User | undefined, data: object = {}, isManual: boolean = true) {
  return useAxios(
    {
        url: `${process.env.REACT_APP_LAMBDA_URL}/${functionName}`,
        method: 'POST',
        data: {currentUser: user?.email, ...data},
    },
    {manual: isManual}
  )
}
