import { Good, Transaction } from '../../interfaces';
import { EmbedDialog } from '../common';
import { useState } from 'react';
import { Box, Button } from '@mui/material';

interface ReferralProps {
  good: Good
  transaction?: Transaction
  overrideText?: string
}

export function ReferralButton(props: ReferralProps) {
  const [openReferralLink, setOpenReferralLink] = useState(false);
  const transactionCompletionDate = props.transaction?.completionDate;
  const goodWebsite = props.good.website;
  const transactionId = props.transaction?.id;
  const isReferralInNewTab = props.good.isReferralInNewTab
  const referralLink = `${props.good.referralLink}`;
  const embedReferralLink = `${referralLink}${transactionId}`

  if (!props.good.isReferral) {
    return null
  }

  return <Box>
    {transactionCompletionDate && <Button 
      href={goodWebsite || ""}
      target="_blank"
      variant="contained"
      size="small"
      disableElevation
      sx={{ mb: 1, pl: 2, pr: 2 }}
    >
      { props.overrideText || `Visit ${props.good.name}` }
    </Button>}
    {!transactionCompletionDate && !isReferralInNewTab && <Button 
      onClick={() =>  setOpenReferralLink(true)}
      variant="contained"
      size="small"
      disableElevation
      sx={{ mb: 1, pl: 2, pr: 2 }}
    >
      { props.overrideText || `Sign up for ${props.good.name}` }
    </Button>}
    {!transactionCompletionDate && isReferralInNewTab && <Button 
      href={referralLink || ""}
      target="_blank"
      variant="contained"
      size="small"
      disableElevation
      sx={{ mb: 1, pl: 2, pr: 2 }}
    >
      { props.overrideText || `Sign up for ${props.good.name}` }
    </Button>}
    {!isReferralInNewTab && <EmbedDialog
      link={embedReferralLink}
      title="referral-link"
      open={openReferralLink}
      setOpen={setOpenReferralLink}
    />}
  </Box>
}
