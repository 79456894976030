import { Backdrop, CircularProgress, Grid } from "@mui/material"

const CircularProgressBackdrop = (props: {open: boolean}) => {

  return (
    <Backdrop
      open={props.open}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Grid container spacing={0} sx={{ m: 5 }} direction="column" alignItems="center" justifyContent="center" width={"100%"}>
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <CircularProgress thickness={5} size={80}/>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default CircularProgressBackdrop;