import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from '@mui/material';
import React, { useEffect } from "react";

import { useGlobalStore } from "../../store";

const Login = () => {
  const {
    user,
    isAuthenticated,
    loginWithPopup
  } = useAuth0();

  const updateAccount = useGlobalStore((state) => state.updateAccount);
  
  useEffect(() => {
    if (user && user.email) {

      updateAccount({
        authUserId: user.sub,
        email: user.email
      });
       
      return (undefined)
    }
  }, [updateAccount, user]);


  return (
    <React.Fragment>
      <Box textAlign="center">
        {!isAuthenticated && (
          <Button
            variant="contained"
            onClick={() => { 
              const newTab = window.open(undefined, '_blank');
              loginWithPopup({screen_hint: 'signup'}, {popup: newTab})
            }}
          >
            Create account
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Login;
