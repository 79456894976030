

export function sanitizeEmail(potentialEmail: string | undefined, blankIfUndefined: boolean = false) {
  return potentialEmail ? potentialEmail.toLowerCase().trim() : blankIfUndefined ? potentialEmail : '';
}

export function sanitizeValueIfEmail(
  source: string, 
  emailKey: string, 
  potentialEmail: string | undefined
): string | undefined {
  return emailKey === source ? sanitizeEmail(potentialEmail) : potentialEmail;
}

export function validateEmail(email: string): RegExpMatchArray | null {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};