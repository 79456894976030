import { ComponentProps } from "react";
import { IMaskMixin } from 'react-imask';

import { InputBaseComponentProps, TextField } from '@mui/material';

type MaskProps = ComponentProps<typeof InternalMaskTextField>

const InternalMaskTextField = IMaskMixin((props) => (
  <TextField {...props as any}/>
))

const MaskTextField = (props: InputBaseComponentProps & MaskProps) => {
  return <InternalMaskTextField {...props} />
}

export default MaskTextField;