import { Grid, Typography } from '@mui/material';
import { SellerLogo } from '../seller/SellerLogo';
import { sanitize } from 'dompurify';

const Welcome = (props: {
  stateCode?: string,
  sellerName?: string,
  sellerMessage?: string
}) => {
  const { stateCode, sellerName, sellerMessage } = props;
  
  const fullSellerMessage = `By signing up here, you can get exclusive access to tools and discounts${
    sellerName ? ` from ${sellerName}` : ""
  }.${sellerMessage ? " " + sanitize(sellerMessage) : ""}`

  return (
    <Grid container>
      <Grid item md={8}>
        <Typography variant="h4" 
          sx={{
            justifyContent: "space-between", 
            paddingTop: 1, paddingLeft: 3, paddingRight: 3, paddingBottom: 4 }}>
          Welcome to DemLaunch, your one-stop shop for getting your campaign up and running.
        </Typography>
        <Typography variant="subtitle1" align={'left'}
          dangerouslySetInnerHTML={{__html: sanitize(fullSellerMessage)}}
          sx={{
            justifyContent: "space-between", 
            paddingLeft: 3, paddingRight: 3, paddingBottom: 4 }} />
      </Grid>
      <Grid item md={4}
        justifyContent="center"
        sx={{ pr: 3, display: { sm: 'none', md: 'flex' }}}
      >
        {stateCode && <SellerLogo large={true} />}
      </Grid>  
    </Grid>
  )
}

export default Welcome;