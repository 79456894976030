
import {
  DialogTitle,
} from '@mui/material';

import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.common.stacBlue,
}));

export default StyledDialogTitle;