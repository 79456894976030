import { useQuery } from "@apollo/client";
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import { Alert, Box, Stack, Typography } from '@mui/material';

import { getStateTemplate } from '../../queries';
import Welcome from '../newCampaign/Welcome';

import _ from 'lodash';
import GetStarted from '../newCampaign/GetStarted';
import { SellerLogo } from "./SellerLogo";
import { AvailableTools } from "../tools/AvailableTools";
import { CircularProgressBackdrop } from '../common';
import { useGlobalStore } from "../../store";

function SellerPage() {
  /** Read URL Parameters */
  const { stateCode } = useParams();

  const { seller, sellerCode, sellerTemplate, updateSeller } = useGlobalStore((state) => state)

  /** Load page data from Graphql */
  const filter = ! _.isEmpty(sellerCode)
    ? { sellerExternalId: { externalId: sellerCode, externalIdType: "JiraProjectId"}} 
    : stateCode ? 
      { sellerExternalId: { externalId: stateCode.toUpperCase(), externalIdType: "JiraProjectId"}} : {};

  const { loading, error, data: templateQueryData } = useQuery(getStateTemplate, {
    variables: {
      filter: filter
    }, 
    skip: ! _.isEmpty(seller)
  });

  useEffect(() => {
    if (templateQueryData?.optionTemplates[0] && stateCode) {
      updateSeller(
        templateQueryData?.optionTemplates[0].seller,
        stateCode,
        templateQueryData?.optionTemplates[0]
      )
    }
  },[ templateQueryData, stateCode, updateSeller ])

  if (error) {
    console.error(error);
    
    return(
      <Alert severity="error">
        There was an error loading the page.
        Please refresh your page and try again, or contact { seller && seller.name ? seller.name : 'your state party' }.
      </Alert>
    )
  }

  return (
    <React.Fragment>

      <Stack direction='row' sx={{pt:3, pl: 3, pr: 3, display: 'flex', alignItems: 'center' }} >
        <Box sx={{ pr: 1, display: { sm: 'flex', md: 'none' }}}>
          <SellerLogo />
        </Box>
        <Typography variant='subtitle2'>
          { seller.name }
        </Typography>
      </Stack>
      <Welcome
        stateCode={sellerCode}
        sellerName={seller.name}
        sellerMessage={seller.sellerPageMessage} /> 
      <CircularProgressBackdrop open={loading} />
      <GetStarted optionTemplate={sellerTemplate}/>
      <AvailableTools optionTemplate={sellerTemplate}/>
    </React.Fragment>
  );
}

export default SellerPage;
