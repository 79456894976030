export interface NewBuyerCreatedLocation {
  newBuyerCreated: boolean
}

export function isNewBuyerCreatedLocation(item: NewBuyerCreatedLocation | unknown): item is NewBuyerCreatedLocation {
  if (!item) {
    return false
  }
  if ((item as NewBuyerCreatedLocation).newBuyerCreated) {
    return true;
  }

  return false;
}