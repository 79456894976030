import { Button, Dialog, DialogContent, LinearProgress } from '@mui/material';
import { Buyer, BuyerPerson, Person } from '../../interfaces';
import { BuyerSelect } from './BuyerSelect';
import { StyledDialogActions, StyledDialogTitle } from '../common';


interface BuyerSelectDialogProps {
  isOpen: boolean;
  isAccountLoading: boolean;
  person: Person;
  buyer: Buyer;
  buyerPersons: BuyerPerson[];
  handleClose: () => void;
}


export const BuyerSelectDialog = (props: BuyerSelectDialogProps) => {

  const { isOpen, isAccountLoading, person, buyer, buyerPersons, handleClose } = props;

  return (
    <Dialog
      open={isOpen}
      maxWidth={'xl'}
      onClose={handleClose}
    >
      <StyledDialogTitle>
        {isAccountLoading && "Loading your committees..."}
        {person && "Select a Committee"}
      </StyledDialogTitle>
      <DialogContent>
        {isAccountLoading && (<LinearProgress />)}
        {person && (
          <BuyerSelect
            buyerPersons={buyerPersons}
            currentBuyer={buyer}
            personId={person.id}
            handleClose={handleClose}
          />
        )}
      </DialogContent>
      <StyledDialogActions>
        <Button color="inherit" onClick={handleClose}>Close</Button>
      </StyledDialogActions>
    </Dialog>
  )

}