import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { FormControl } from '@mui/material';

interface BasicDatePickerProps {
  label: string
  value: DateTime | null
  onChange: any
  required?: boolean
  disabled?: boolean
}

const BasicDatePicker = (props: BasicDatePickerProps) => {
  const [error, setError] = useState<DateValidationError | null>(null);

  const errorMessage = useMemo(() => {
    if (error) {
      return 'Please enter a valid future date in the MM/DD/YYYY format';
    } else {
      return ''
    }
  }, [error]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <FormControl fullWidth>
        <DesktopDatePicker
          label={props.label}
          format="MM/dd/yyyy"
          value={props.value}
          disabled={props.disabled}
          onChange={props.onChange}
          disablePast={true}
          slotProps={{ textField: {
            required: props.required,
            inputProps: {pattern: '[0-1][0-9]/[0-3][0-9]/20[0-9][0-9]'},
            helperText: errorMessage
          } }}
          onError={(newError) => setError(newError)}
        />
      </FormControl>
    </LocalizationProvider>
  )
}

export default BasicDatePicker;