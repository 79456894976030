import _ from 'lodash';
import { User } from "@auth0/auth0-react";
import React from 'react';

import { Good, Transaction } from '../../interfaces';
import { ContractButton } from './ContractButton';
import { PaymentButton } from './PaymentButton';
import { getFirstValueIfPresent, useAxiosGetS3Url } from '../../utils/transactions';
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransactionActivity } from './TransactionActivity';

interface TransactionHistoryProps {
  good: Good
  transaction?: Transaction
  user?: User;
}

export function TransactionHistory(props: TransactionHistoryProps) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const good = props.good;
  const transaction = props.transaction
  const user = props.user

  const invoiceKey = getFirstValueIfPresent(transaction, "s3KeyInvoice")
  
  const [{ data: s3UrlData}] = useAxiosGetS3Url(transaction, ["s3KeyInvoice"], invoiceKey, user)

  const canPay = !_.isUndefined(transaction) && !transaction?.customDataJson['amountPaid']
    
  return (
    <div>
      <Button 
        onClick={handleClickOpen} 
        variant="contained"
        size="small"
        disableElevation
        sx={{ mb: 1, pl: 2, pr: 2 }}
        disabled={_.isUndefined(transaction) || transaction?.activities?.length === 0}
      >
        View history
      </Button>
      <Dialog
        open={open}
        maxWidth={'xl'}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container sx={{ mb: 1 }} spacing={1}>
            <Grid item xs={8}>
              <Grid item xs={8}>
              <Typography variant='overline'>
                {_.startCase(transaction?.paymentPlan?.status?.toString() || transaction?.status.toString() || '')}
              </Typography>
            </Grid>
              <Typography variant='h6'>
                {good.name} {
                  transaction?.customDataJson?.TicketId ? `(${transaction?.customDataJson?.TicketId})` : ''
                }
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            { canPay && <Grid item xs>
            <PaymentButton transaction={transaction} /> 
            </Grid>}
            { invoiceKey &&  <Grid item xs>
                <Button 
                  variant="contained"
                  size="small"
                  disableElevation
                  sx={{ mb: 1, pl: 2, pr: 2 }}
                  href={s3UrlData ? s3UrlData[0] : ''}
                  target="_blank"
                >
                  Download Invoice 
                </Button>
              </Grid>}
            <Grid item xs>
              <ContractButton transaction={transaction} user={user} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          { transaction?.activities.map((activity) => (
            <Card variant="outlined" key={activity.id} sx={{ mb: 1, mt: 1, pt: 1 }}>
              <TransactionActivity activity={activity} transaction={transaction} user={user} />
            </Card>
            ))
          }
        </DialogContent>
      </Dialog>
    </div>
  )
}
