import _ from 'lodash';
import { User } from "@auth0/auth0-react";
import { MouseEvent } from 'react';

import { Transaction } from '../../interfaces';
import { getFirstValueIfPresent, useAxiosGetS3Url } from '../../utils/transactions'
import { Alert, Button } from '@mui/material';
import { useLambdas } from '../../utils/lambdas';

interface ContractProps {
  transaction: Transaction | undefined
  isDisabled?: boolean 
  user: User | undefined;
}

export function ContractButton(props: ContractProps) {

  const transaction = props.transaction
  const user = props.user

  const contractId = getFirstValueIfPresent(transaction, "hellosignRequestId");
  const signedContractS3Link = getFirstValueIfPresent(transaction, "s3KeyContract");
  
  const [{ data: s3UrlData}] = useAxiosGetS3Url(transaction, ["s3KeyContract"], signedContractS3Link, user)
  
  const [{ data: remindContractData, loading: isRemindContractLoading, error: remindContractError}, remindContract] = 
    useLambdas(
      "remindContract", 
      user, 
      {"detail": 
        {
        "transactionId": transaction ? transaction.id : 0
        },
        "hellosignRequestId": contractId
      }
    )
    
    const remindContractClick= (e: MouseEvent): void => {
      if (contractId && !signedContractS3Link) {
        remindContract()
      } 
    }
  
  if (!transaction?.hasContract) {
    return (<div/>)
  }

  return (
    <div>
      <Button 
        href={s3UrlData ? s3UrlData[0] : ""}
        onClick={remindContractClick}
        target="_blank" 
        variant="contained"
        size="small"
        disableElevation
        sx={{ mb: 1, pl: 2, pr: 2 }}
        disabled={ props.isDisabled || _.isUndefined(contractId) || isRemindContractLoading || Boolean(remindContractError) || Boolean(remindContractData)} >
          {signedContractS3Link ? "View contract" : `Resend contract` } 
      </Button>
      { remindContractError ? 
        <Alert severity="error">
          Error resending contract. Can only send once per hour. Check your spam folder if it didn't arrive.
        </Alert> 
      : ""}
      { remindContractData ? 
        <Alert severity="success">
          Reminder(s) sent to: {remindContractData}. Check your spam if you don't see the email within 10 minutes.
        </Alert> 
      : ""}
    </div>
  )
}
