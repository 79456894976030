import { StateCreator } from 'zustand'

import { Account } from '../interfaces'

export interface AccountState {
  account: Account
  updateAccount: (account: Account) => void
}

export const createAccountSlice: StateCreator<
  AccountState,
  [],
  [],
  AccountState  
> = (set, get) => ({
  account: {} as Account,
  buyerPersons: [],
  updateAccount: (account: Account) => { 
    set(() => ({ account }))
  },
})
