import { Button, Typography } from '@mui/material';
import { BuyerOptionProps } from '.';

import React, { useEffect, useState } from 'react';
import { useLambdas } from '../../../utils/lambdas';
import useAxios from 'axios-hooks';


export default function Attachment(props: BuyerOptionProps) {
  const [ s3Url, setS3Url ] = useState<string | undefined>(props.currentValue || '');

  const { onDetailChange, optionKey, isEditMode, id, buyerDetailId } = props;
  const consolidatedId = isEditMode ? buyerDetailId : id;

  const [ file, setFile ] = useState<File | undefined | null>();
  const [ isFileUploading, setIsFileUploading ] = useState(false);
  
  const [{ data: presignedUrl, loading: presignedUrlLoading, response: presignedUrlResponse }, getUploadS3Url] = useLambdas(
    "getUploadS3Url",
    undefined,
    {
      s3Key: file?.name
    }
  )

  const [ { response: putResponse }, uploadFileToS3] = useAxios(
    {
      method: 'PUT',
      data: file
    },
    {manual: true}
  );

  useEffect(() => {   
    if (putResponse) {
      const url = putResponse.config.url?.split('?')[0].split("/").splice(3).join("/");
      setS3Url(url);
      onDetailChange(
          optionKey,
          url,
          consolidatedId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putResponse])

  useEffect(() => {
    // run the useLambdas hook here to get a new presigned url 

    if (file) {
      getUploadS3Url();
      setIsFileUploading(true);
    }

  }, [file, getUploadS3Url]);


  useEffect(() => {
    if (isFileUploading && ! presignedUrlLoading) {

      uploadFileToS3({
        url: presignedUrl,
        data: file,
      })

      setIsFileUploading(false)
    }
  }, [isFileUploading, presignedUrlLoading, presignedUrlResponse, uploadFileToS3, file, presignedUrl])


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.item(0));
  }

  const truncateS3Link = () : string => {
    let link = s3Url || '';
    return link.substring(link.lastIndexOf('/') + 1);
  }

  return (
    <label htmlFor={`upload-attachment-${consolidatedId}`}>
      <input
        style={{ display: 'none' }}
        id={`upload-attachment-${consolidatedId}`}
        name="upload-attachment"
        type="file"
        onChange={handleChange}
      />

      <table>
        <tbody>
          <tr>
            <td>
              <Button color="secondary" variant="contained" component="span">
                Upload {props.label}
              </Button>
            </td>
            <td>
              <Typography>
                {truncateS3Link()}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>

    </label>
  );
}
