import _ from "lodash";
import { Transaction } from '../interfaces';
import { User } from '@auth0/auth0-react';
import { useLambdas } from './lambdas';

export function getFirstValueIfPresent(transaction: Transaction | undefined, customDataField: string): string | undefined {
    if (!transaction) {
      return undefined
    }
    let data = transaction.customDataJson[customDataField];
    if (!data) {
      return undefined
    }
    return data[0].value
  }

export const getMostRecentCustomData = (transaction: Transaction | undefined, key: string, sortBy:string = 'date') => {
  if (!transaction) {
    return undefined
  }
    const maxData: any = _.maxBy(transaction.customDataJson[key], sortBy);
    if (maxData) {
      return maxData['value'];
    } else {
      return transaction.customDataJson[key]
    }
  }

export function useAxiosGetS3Url(transaction: Transaction | undefined, s3Key: string[], pullWhenSet: string | undefined, user: User | undefined) {
  return useLambdas(
    "getS3Url",
    user,
    {"detail": 
      {
        "transactionId": transaction ? transaction.id : 0
      },
      "s3KeyTypes": s3Key
    },
    (pullWhenSet === undefined)
  )

}
