import _ from "lodash";
import { Fragment, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { User } from "@auth0/auth0-react";

import { BaseBuyerOption } from "./BaseBuyerOption";
import { BuyerDetail, BuyerDetailType, BuyerOption, ExternalId } from "../../interfaces";
import { useLambdas } from "../../utils/lambdas";
import { CircularProgressBackdrop } from '../common';

export enum SubsectionType {
  existingDetail,
  newDetail,
  existingPerson,
  newPerson
}

interface EditDetailSubsectionProps {
  subsectionType: SubsectionType
  sectionTitle: string
  buyerId?: number
  buyerDetails?: BuyerDetail[]
  buyerOptions?: BuyerOption[]
  answeredOptions?: number[]
  user?: User
}

export const EditDetailSubsection = (props: EditDetailSubsectionProps) => {
  const [ isLockedMode, setIsLockedMode ] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ buyerDetailValue, setBuyerDetailValue ] = useState({});

  const [, executePost] = useLambdas(
    "updateBuyer",
    props.user,
    props.subsectionType === SubsectionType.existingDetail ? {
      buyerId: props.buyerId,
      existingBuyerDetails: buyerDetailValue
    } : {
      buyerId: props.buyerId,
      newBuyerDetails: buyerDetailValue
    }
  )

  const onSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await executePost();
    } catch (err) {
      console.log(err)
    }

    setIsSubmitting(false);
    setIsLockedMode(true);
  }

  const onBuyerDetailChange = (option: string, value: string, id: number, externalId?: ExternalId) => {
    let newValue: any = props.subsectionType === SubsectionType.existingDetail ? {
      detailValue: value,
      detailId: { id: id }
    } :{ optionValue: value, optionId: { id: id } };

    if (externalId) {
      newValue = { ...newValue, externalId };
    }

    setBuyerDetailValue(prevValue => ({
      ...prevValue,
      [option]: newValue
    }))
  }

  const orderedBuyerDetails = props.buyerDetails && _.orderBy(
    props.buyerDetails, ['option.detailType', 'option.uiOrder']
  )

  const orderedBuyerOptions = props.buyerOptions && _.orderBy(
    props.buyerOptions, ['detailType', 'uiOrder']
  )

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10} sx={{ mb: 2 }}>
          <Typography variant="h6" align="left">
            {props.sectionTitle}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ mb: 2 }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setIsLockedMode(false)}>
              <EditOutlined color="primary" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item container>
          {props.subsectionType === SubsectionType.existingDetail && orderedBuyerDetails?.map(buyerDetail => {
            return <BaseBuyerOption
                    key={buyerDetail.key}
                    option={buyerDetail.option} onDetailChange={onBuyerDetailChange}
                    currentValue={buyerDetail.value} isEditMode={true}
                    isDisabled={isLockedMode}
                    buyerDetailId={buyerDetail.id}
                    externalId = {buyerDetail.externalId?.externalId} />
          })}
          {props.subsectionType === SubsectionType.newDetail && orderedBuyerOptions?.map(option => {
            return !props.answeredOptions?.includes(option.id) &&
                  [BuyerDetailType.buyer, BuyerDetailType.buyerCore].includes(option.detailType) &&
                  <BaseBuyerOption
                    key={option.key}
                    option={option} onDetailChange={onBuyerDetailChange}
                    isDisabled={isLockedMode}
                  />
          })}
        </Grid>
        { !isLockedMode && <Fragment>
          <Grid item xs={4}>
            <Button variant="outlined" onClick={() => setIsLockedMode(true)}>
              <Typography variant={'subtitle2'}>
                Cancel
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="flex-end">
              <CircularProgressBackdrop open={isSubmitting} />
              <Button
                variant="contained"
                type="submit"
                disableElevation
              >
                <Typography variant={'subtitle2'}>
                  Save changes
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Fragment> }
      </Grid>
    </Box>
  );
}