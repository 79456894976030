import { StateCreator } from 'zustand'
import { BuyerPerson, Buyer, Person } from '../interfaces'
import _ from 'lodash'

export interface PersonBuyerState {
  buyer: Buyer
  buyerWithDetails: Buyer
  person: Person
  buyerPersons: BuyerPerson[]
  currentBuyerPersons: BuyerPerson[]
  refetch: Function
  updatePerson: (person: Person) => void
  updateBuyer: (buyer: Buyer) => void
  updateBuyerWithDetails: (buyerWithDetails: Buyer) => void
  updateRefetch: (refetch: Function) => void
}

export const createPersonBuyerSlice: StateCreator<
  PersonBuyerState,
  [],
  [],
  PersonBuyerState
> = (set, get) => ({
  person: {} as Person,
  buyer: {} as Buyer,
  buyerWithDetails: {} as Buyer,
  buyerPersons: [],
  currentBuyerPersons: [],
  refetch: () => {return null},
  updateBuyer: (buyer: Buyer) => { 

    let newState = {
      buyer: buyer,
      currentBuyerPersons: get().currentBuyerPersons
    }

    if (get().buyerPersons.length > 0 && _.isEmpty(buyer)) {
      const defaultBuyer = get().buyerPersons.find(buyerPerson => {
        return buyerPerson.buyer?.id === get().person?.defaultBuyerId
      }) || get().buyerPersons[0];

      newState.buyer = defaultBuyer.buyer;
    }
    newState.currentBuyerPersons = get().buyerPersons.filter(
      buyerPerson => buyerPerson.buyer.id === newState.buyer.id
    )

    set(() => ({ ...newState }))
  },
  updateBuyerWithDetails: (buyerWithDetails: Buyer) => { 

    set(() => ({ buyerWithDetails }))
  },
  updatePerson: (person: Person) => {

    let newState = {
      person, 
      buyer: get().buyer,
      buyerPersons: person ? _.orderBy(_.uniqWith(person.buyers, _.isEqual), "buyer.name") : [],
      currentBuyerPersons: get().currentBuyerPersons
    }

    if (_.isEmpty(get().buyer) && ! _.isEmpty(person)) {
      const defaultBuyer = newState.buyerPersons.find(buyerPerson => {
        return buyerPerson.buyer.id === person?.defaultBuyerId
      }) || newState.buyerPersons[0];

      newState.buyer = defaultBuyer.buyer;
      newState.currentBuyerPersons = newState.buyerPersons.filter(
        buyerPerson => buyerPerson.buyer.id === newState.buyer.id
      )
    }

    set(() => ({ ...newState }))
  },
  updateRefetch: (refetch: Function) => {
    set(() => ({ refetch }))
  }
})
