import _ from 'lodash';
import { Button, Grid, Typography } from '@mui/material';
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from 'react';
import { useNavigate, Location, useLocation, useParams } from "react-router-dom";

import { isBuyerDetailsLocation } from '.';
import { BaseBuyerOption } from '../buyer/BaseBuyerOption';
import { Account, BuyerDetailType, BuyerOption, BuyerPerson, ExternalId, IDInput,
          PersonValue } from '../../interfaces';
import Login from '../header/Login';
import { updatePerson } from '../../queries';
import { useLambdas } from "../../utils/lambdas";
import { useGlobalStore } from "../../store";
import { CircularProgressBackdrop } from '../common';
import { sanitizeEmail } from '../../utils/email';


interface BuyerInput {
  name: string,
  website?: string,
  sellerId?: number,
  buyerDetails?: BuyerDetailValue[],
  persons?: PersonValue[],
  account?: Account
}

interface BuyerDetailValue {
  buyerOption: IDInput;
  value: string;
}

interface BasicStateItem {
  optionValue: string;
  optionId: IDInput;
  externalId?: ExternalId
}

interface BuyerCoreSectionState {
  [key: string]: BasicStateItem | undefined;
}

interface BuyerSectionState {
  [key: string]: BasicStateItem
}

interface PersonStateItem {
  optionId: IDInput;
  optionValue: PersonValue;
}

interface KeyPersonSectionState {
  [key: string]: PersonStateItem
}

interface PersonSectionState {
  [key: string]: PersonStateItem
}

export function BuyerDetails() {

  /** Set up state objects */
  const [ buyerCoreDetailValues, setBuyerCoreDetailValue ] = useState<BuyerCoreSectionState>({});
  const [ buyerDetailValues, setBuyerDetailValue ] = useState<BuyerSectionState>({});

  const [ keyPersonDetailValues, setKeyPersonDetailValue ] = useState<KeyPersonSectionState>({});
  const [ personDetailValues, setPersonDetailValue ] = useState<PersonSectionState>({});

  const [ buyerInput, setBuyerInput ] = useState<BuyerInput>();
  
  const account = useGlobalStore((s) => s.account);
  const refetchAccount = useGlobalStore((s) => s.refetch);

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  const [ saveDefaultBuyer] = useMutation(updatePerson);

  const [, executePost] = useLambdas(
    "addBuyer",
    undefined,
    { buyer: buyerInput }
  )

  /** Read URL Parameters */
  const { updateBuyer } = useGlobalStore();
  const location: Location = useLocation();
  const { stateCode } = useParams();
  const navigate = useNavigate();

  const locationDetails = isBuyerDetailsLocation(location.state) ? location.state : undefined;
  const optionTemplate = locationDetails?.optionTemplate;
  
  useEffect(() => {
    if (!optionTemplate) {
      navigate(`/${stateCode}`)
    }
  });

  useEffect(() => {
    let buyerInputValue: BuyerInput = {
      name: buyerCoreDetailValues.CampaignName ? buyerCoreDetailValues.CampaignName.optionValue.trim() : '', 
      website: buyerCoreDetailValues.Website?.optionValue,
      sellerId: optionTemplate?.seller.id,
      account: account && { email: account.email }
    }

    buyerInputValue.persons = _.map(keyPersonDetailValues, (person) => {
      return { ...person.optionValue }
    }).concat(_.map(personDetailValues, (person) => {
      return { ...person.optionValue }
    }))

    const tempBuyerCoreDetails = _.map(buyerCoreDetailValues, (buyerOpt) => {
      if (buyerOpt) {
        return buyerOpt.externalId ? {
          value: buyerOpt.optionValue,
          buyerOption: buyerOpt.optionId,
          externalId: buyerOpt.externalId
        } : {
          value: buyerOpt.optionValue,
          buyerOption: buyerOpt.optionId
        }
      } else {
        return { value: '', buyerOption: { id: -1 }}
      }      
    });

    const tempBuyerDetails = _.map(buyerDetailValues, (buyerOpt) => {
      return buyerOpt.externalId ? {
        value: buyerOpt.optionValue,
        buyerOption: buyerOpt.optionId,
        externalId: buyerOpt.externalId
      } : {
        value: buyerOpt.optionValue,
        buyerOption: buyerOpt.optionId
      }
    });

    buyerInputValue.buyerDetails =_.concat(
      tempBuyerCoreDetails,
      tempBuyerDetails
    )
    
    setBuyerInput(buyerInputValue);
  }, [buyerCoreDetailValues, optionTemplate, account, keyPersonDetailValues, personDetailValues, buyerDetailValues])
  
  /** Event Handlers */
  const onBuyerCoreDetailChange = (option: string, value: string, id: number, externalId?: ExternalId) => {
    setBuyerCoreDetailValue(prevValue => ({
      ...prevValue,
      [option]: externalId ? {
        optionValue: value, optionId: { id: id }, externalId: externalId
      } : { optionValue: value, optionId: { id: id } }
    }))
  }

  const onBuyerDetailChange = (option: keyof BuyerSectionState, value: string, id: number, externalId?: ExternalId) => {
    setBuyerDetailValue(prevValue => ({
      ...prevValue,
      [option]: externalId ? {
        optionValue: value, optionId: { id: id }, externalId: externalId
      } : { optionValue: value, optionId: { id: id } }
    }))
  }

  const onKeyPersonDetailChange = (option: keyof KeyPersonSectionState, value: PersonValue, id: number) => {
    setKeyPersonDetailValue(prevValue => ({
      ...prevValue,
      [option]: { optionValue: value, optionId: { id: id }}
    }))
  }

  const onPersonDetailChange = (option: keyof PersonSectionState, value: PersonValue, id: number) => {
    setPersonDetailValue(prevValue => ({
      ...prevValue,
      [option]: { optionValue: value, optionId: { id: id }}
    }))
  }

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    
    const buyerResult = await executePost();
    const newBuyer = buyerResult.data;
    const buyerPerson: BuyerPerson = newBuyer.buyerPersons.find((buyerPerson: BuyerPerson) => {
      return sanitizeEmail(buyerPerson.person.email) === sanitizeEmail(account?.email);
    });

    await saveDefaultBuyer({
      variables: {
        person: {
          id: buyerPerson.person.id,
          defaultBuyerId: newBuyer.id
        }
      }
    });

    updateBuyer(newBuyer);
    refetchAccount();
    navigate('/', { state: { newBuyerCreated: true }});
  }

  const buyerOptions = _.sortBy(optionTemplate?.buyerOptions, ['uiOrder']);

  const buyerCoreDetails = _.filter(buyerOptions, { detailType: BuyerDetailType.buyerCore});
  const buyerDetails = _.filter(buyerOptions, { detailType: BuyerDetailType.buyer});
  const keyPersonDetails = _.filter(buyerOptions, { detailType: BuyerDetailType.keyPerson});
  const personDetails = _.filter(buyerOptions, { detailType: BuyerDetailType.otherPerson});

  // Allows for marking subsequent people as the same as the first
  const firstPersonKey = keyPersonDetails.length > 0 ? keyPersonDetails[0].key : undefined;
  const firstPersonLabel = keyPersonDetails.length > 0 ? keyPersonDetails[0].label : undefined;
  const firstPersonValue = keyPersonDetails.length > 0 ? Object.values(keyPersonDetailValues)[0]?.optionValue : undefined;

  return (
    <Grid container component="form" onSubmit={onSubmit} sx={{ mb: 4 }}>
      <Grid container sx={{ m: 2 }} spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>
            Campaign details
          </Typography>
          <Typography variant={'subtitle2'} sx={{ paddingBottom: 2 }}>
            Tell us a little more about { locationDetails?.showStaffMessage ? "who's running" : "you" }
          </Typography>
        </Grid>
        <Grid container sx={{ m: 2 }}>
          {
            buyerCoreDetails?.map((option: BuyerOption) => {
              return <BaseBuyerOption key={option.id} option={option} onDetailChange={onBuyerCoreDetailChange} />
            })
          }
        </Grid>
        <Grid container sx={{ m: 2 }}>
          {
            buyerDetails?.map((option: BuyerOption) => {
              return <BaseBuyerOption key={option.id} option={option} onDetailChange={onBuyerDetailChange} />
            })
          }
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          Please provide information about yourself and any others on the campaign:
        </Grid>
        <Grid container sx={{ m: 2 }} width="100%">
          {
            keyPersonDetails?.map((option: BuyerOption) => {
              return <BaseBuyerOption
                      key={option.id} option={option} onDetailChange={onKeyPersonDetailChange}
                      firstPersonKey={firstPersonKey} firstPersonLabel={firstPersonLabel} firstPersonValue={firstPersonValue}/>
            })
          }
          {
            personDetails?.map((option: BuyerOption) => {
              return <BaseBuyerOption
                      key={option.id} option={option} onDetailChange={onPersonDetailChange}
                      firstPersonKey={firstPersonKey} firstPersonLabel={firstPersonLabel} firstPersonValue={firstPersonValue}/>
            })
          }
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <CircularProgressBackdrop open={isSubmitting} />
        { _.isEmpty(account) ? <Login/> : <Button
            variant="contained"
            className="btn-margin"
            size="large"
            disableElevation
            color="primary"
            type="submit"
          >
            <Typography variant={'subtitle2'}>
              Submit
            </Typography>
          </Button>}
      </Grid>
    </Grid>
  );
}
