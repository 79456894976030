import { Activity, ActivityType, Transaction } from '../../interfaces';
import { User } from "@auth0/auth0-react";
import moment from 'moment' 
import { Box, Button, Grid, Typography } from '@mui/material';
import { useAxiosGetS3Url } from '../../utils/transactions';
import _ from 'lodash';
import { ReactNode } from 'react';
import { sanitize } from 'dompurify';

interface ActivityProps {
  activity: Activity
  transaction: Transaction | undefined
  user: User | undefined
}

const DATE_FORMAT = 'MMM DD, YYYY h:mm a'

function getS3Key(activity: Activity): [string | undefined, string | undefined] {
  if (activity.activityType !== ActivityType.S3FileUpload) {
    return [undefined, undefined]
  }
  const filepath = activity.details.filepath || ""
  const regexp = /\s(\w+)( - phase due|\.pdf$)/
  const match = filepath.match(regexp)
  if (match && match?.length >= 2) {
    return ["s3Key" + _.upperFirst(match[1]), filepath]
  }
  throw Error("Unable to determine s3 key for upload")
}

export function TransactionActivity(props: ActivityProps) {

  const activity = props.activity
  const activityType = activity.activityType
  
  const [s3KeyFromFileUploadActivity, filepath] = getS3Key(activity)

  const s3Keys = activity.details.filepaths ? 
    activity.details.filepaths.map(filepath => filepath.s3Key):
    [s3KeyFromFileUploadActivity || ''];

  const [{ data: s3UrlData}] = useAxiosGetS3Url(
    props.transaction,
    s3Keys,
    filepath || (activity.details.filepaths && 'multiple files'),
    props.user
  )

  if (activityType === ActivityType.Email) {
    return generateEmailDisplay(activity, s3UrlData)
  }
  else if (activityType === ActivityType.ContractSigned) {
    return generateActivityDisplay("Contract Signed", activity)
  }
  else if (activityType === ActivityType.Payment) {
    return generateActivityDisplay("Payment Received", activity)
  }
  else if (activityType === ActivityType.S3FileUpload) {
    if (!s3UrlData) {
      return null
    }
    return generateUploadDisplay(activity, s3UrlData[0])
  }
  else if ((
    ActivityType.ResendContract, ActivityType.ResendEmail, ActivityType.Notification
  ).includes(activityType)) {
    return generateActivityDisplay("New Notification", activity)
  }
  else  {
    throw Error(`Unknown activity type ${activityType}`)
  }

} 

function generateActivityDisplay(message: string, activity: Activity, content?: ReactNode | undefined) {
  const date = moment(activity.date)

  return (
    <Grid sx={{ ml: 2, mr: 2 }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight={600}>
            {message}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="body2" fontWeight={600}>
            {date.format(DATE_FORMAT)}
          </Typography>
        </Grid>   
      </Grid>
      <Grid sx={{m: 2}}>
        <Typography variant="body2" component={'span'}>
        { content || activity.details?.content || ""}
        </Typography>
      </Grid>
    </Grid>
  )
}

function generateUploadButton(summary: string, s3UrlData: string | undefined, extendedMargin = false) {
  return <Button
    href={s3UrlData || ""}
    target="_blank" 
    variant="outlined"
    sx={{mt: extendedMargin ? 2 : 1}}
  >
    <Typography variant="body2">
      Download {summary}
    </Typography>
  </Button>
}

function generateUploadDisplay(activity: Activity, s3UrlData: string | undefined) {
  return generateActivityDisplay(
    "New Attachment", activity, generateUploadButton(activity.summary, s3UrlData)
  )
}

function generateEmailDisplay(activity: Activity, s3UrlData: string[] | undefined) {
  const message = `Email: ${activity.details.subject}`
  const attachments = s3UrlData?.map((singleAttachment, index) => {
    const summary = s3UrlData.length > 1 ? `Attachment ${index + 1}` : "Attachment";
    return generateUploadButton(summary, singleAttachment, true)
  });

  const contentDiv = (
    <Box>
      <div dangerouslySetInnerHTML={{__html: sanitize(activity.details.content || "")}} />
      {attachments}
    </Box>
  )
  return generateActivityDisplay(message, activity, contentDiv)
}