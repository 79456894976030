
import Attachment from './Attachment'
import Checkbox from './Checkbox';
import DateComponent from './Date';
import DateTime from './DateTime';
import EEDistrict from './EEDistrict';
import MultiSelect from './MultiSelect';
import Number from './Number';
import Paragraph from './Paragraph';
import Person from './Person';
import RadioGroupControl from './RadioGroup';
import SingleSelectWithOther from './SingleSelectWithOther';
import SingleSelectWithoutOther from './SingleSelectWithoutOther';
import Text from './Text';
import Url from './Url';

import get from "lodash/get";

export class BuyerOptionRegistry {

  registry: Object

  constructor( ) {
    this.registry = { components: {
      Attachment: Attachment,
      Checkbox: Checkbox,
      Date: DateComponent,
      DateTime: DateTime,
      EEDistrict: EEDistrict,
      MultiSelect: MultiSelect,
      Number: Number,
      Paragraph: Paragraph,
      Person: Person,
      RadioGroup: RadioGroupControl,
      SingleSelectWithOther: SingleSelectWithOther,
      SingleSelectWithoutOther: SingleSelectWithoutOther,
      Text: Text,
      Url: Url
    }};
  }

  getComponent(id: string): any {
    return get(this.registry, `components.${id}`, null)
  } ;
}
