import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './components/App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { LocalStorageCache } from '@auth0/auth0-react';

const client = new ApolloClient({
  uri: process.env.REACT_APP_LEDGER_URL,
  cache: new InMemoryCache()
});

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  cache: new LocalStorageCache(),
  useRefreshTokens: true,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin
};

const container = document.getElementById('root');
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
