import { Box, Typography } from '@mui/material';
import { CircularProgressBackdrop } from './common';

import React from "react";
import { useLocation } from "react-router-dom";

const PAGE_CLOSE_TIMEOUT: number = 5000;

function PageClose() {
  const loading: boolean = false;

  setTimeout(() => {
      window.close();
      }, PAGE_CLOSE_TIMEOUT
  );
  const search = useLocation().search;
  const redirectType = new URLSearchParams(search).get('redirectType');

  return (
    <Box sx={{ pt: 4, padding: 2}}>
      <CircularProgressBackdrop open={loading} />
        {redirectType === "success" &&
           <Typography variant="h6">
            Thank you! We have received your payment and you may now close this window. Check your email for next steps!
           </Typography>
        }
        {redirectType === "cancel" &&
           <Typography variant="h6">
            Transaction cancelled. Window will attempt to close in 5 seconds...
           </Typography>
        }
        {!redirectType &&
           <Typography variant="h6">
            Window will attempt to close in 5 seconds...
           </Typography>
        }
    </Box>
  );
}

export default PageClose;
