import _ from "lodash";
import { User } from "@auth0/auth0-react";
import { Alert, Grid, Typography } from "@mui/material";

import { Seller, Transaction } from "../../interfaces";
import { ToolProgressCard } from "./ToolProgressCard";

interface RequestedToolsProps {
  transactions?: Transaction[]
  sellers?: Seller[]
  user?: User
  refetchPage?: Function
}

export const RequestedTools = (props: RequestedToolsProps) => {
  const { transactions } = props;

  return (
    <Grid container sx={{ pb: 4, pt: 2, pr: 5, pl: 5 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h6'}>
          My tools
        </Typography>
      </Grid>
      {transactions && transactions.length === 0 ? <ToolProgressCard /> : transactions?.map(transaction => {
        if (!transaction) {
          return null
        }
        const seller = _.filter(props.sellers, {id: transaction.process.seller.id})[0]
        if (!seller || seller.optionTemplates.length === 0) {
          return <Alert severity="error">Cannot show details of this committee. Please email demlaunch@staclabs.io, and we will investigate.</Alert>
        }
        const goodOption = _.filter(
          seller.optionTemplates[0].goodOptions, {good: {id: transaction.process.bundle.goods[0].id}}
        )[0]
        return <ToolProgressCard
          key={transaction.id}
          transaction={transaction}
          goodOption={goodOption}
          user={props.user}
          refetchPage={props.refetchPage}
          isShowSeller={Boolean(props.sellers?.length && props.sellers?.length > 1)}
        />
      })}
      
    </Grid>
  )
}