import _ from "lodash";
import { User } from '@auth0/auth0-react';
import moment from 'moment';

import { Card, CardActions, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Autorenew, Beenhere, Dvr, HelpOutline, MoreVert, NotInterested } from '@mui/icons-material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { GoodOption, PaymentPhase, Permission, ProgressStatus, ProgressStatusText, STARTED_STATUSES, Transaction } from "../../interfaces";
import { useGlobalStore } from "../../store";
import { ContractButton } from '../transaction/ContractButton';
import { PaymentButton } from "../transaction/PaymentButton";
import { TransactionHistory } from "../transaction/TransactionHistory";
import { ReferralButton } from "../transaction/ReferralButton";
import { getFirstValueIfPresent, getMostRecentCustomData } from '../../utils/transactions';
import { TransactionCancelation } from "../transaction/TransactionCancelation";
import { hasAnyPermission, hasPermission } from "../../utils/permissions";
import { Fragment } from "react";

interface ToolProgressCardProps {
  transaction?: Transaction
  goodOption?: GoodOption
  isRequestable?: boolean
  isShowSeller?: boolean
  user?: User
  refetchPage?: Function
}

const ProgressStatusIcon = {
  NotStarted: <Autorenew fontSize='small'/>,
  InProgress: <Autorenew fontSize='small'/>,
  Complete: <Beenhere fontSize='small'/>,
  Triage: <Autorenew fontSize='small'/>,
  Skipped: <Autorenew fontSize='small'/>,
  Abandoned: <NotInterested fontSize='small'/>,
  Canceled: <NotInterested fontSize='small'/>
}

enum HelperText {
  open = "Thanks for requesting a tool! We aim to reach back out in 1-2 business days to process your request.",
  documentsSent = "You've been approved - please check the history for next steps.",
  documentsReceived = "We've received your contact and/or payment – we'll be reaching out in 1-2 business days with next steps.",
  triage = "Please contact the seller for more information.",
  awaitingPrimaryResults = "Thanks for requesting software from us - we'll be reaching out after the primary with next steps.",
  secondInstallment = "Your 2nd payment installment is due."
}

const WORKFLOW_EXTERNAL_ID_TO_HELPER_TEXT: any = {
  "Approved": HelperText.documentsSent,
  "Awaiting Primary Results": HelperText.awaitingPrimaryResults,
  "Documents Received": HelperText.documentsReceived,
  "Documents Sent": HelperText.documentsSent,
  "Federal Coordinated Sent": HelperText.documentsSent,  
  "General Election Payment Due": HelperText.secondInstallment,
  "In-Kind Received": HelperText.documentsReceived,
  "In-Kind Sent": HelperText.documentsSent,
  "Non-Federal Coordinated Sent": HelperText.documentsSent,
  "Open": HelperText.open,
  "Payment Received": HelperText.documentsReceived,
  "Refundable Payment Due": HelperText.documentsReceived,
  "Refundable Received": HelperText.documentsReceived,
  "Refundable Sent": HelperText.documentsSent,
  "State Leg Received": HelperText.documentsReceived,
  "State Leg Sent": HelperText.documentsSent,
  "Triage/Consultation": HelperText.triage,
  "Waiting for Customer": HelperText.open,
  "Waiting for Response": HelperText.open,
}

function getLastPaymentPlanPayment(phases: PaymentPhase[]): [number | undefined, string | undefined] {
  if (!phases) {
    return [undefined, undefined]
  }
  let lastDatePaid: Date | undefined = undefined
  let lastAmountPaid = undefined
  phases.forEach(phase => {
    if (phase.completionDate && phase.amountPaid) {
      if (!lastDatePaid || phase.completionDate > lastDatePaid) {
        lastDatePaid = phase.completionDate
        lastAmountPaid = phase.amountPaid          
      }
    }
  })
  return [lastAmountPaid, lastDatePaid]
}

function getInKindPayment(transaction?: Transaction): [String | undefined, Date | undefined] {

  const inKindAmount = transaction?.customDataJson["InKind"];
  const inKindPaymentDate = transaction?.customDataJson["PaymentDate"];

  if (inKindAmount && inKindPaymentDate) {
    return [inKindAmount, inKindPaymentDate ? new Date(inKindPaymentDate) : undefined];
  } 

  return [undefined, undefined];
}

function getMostRecentPayment(transaction?: Transaction): [string | undefined, string | undefined, string | undefined] {
  const initialAmountPaid = getFirstValueIfPresent(transaction, "amountPaid");
  const paymentInitiatedDate = getMostRecentCustomData(transaction, "paymentInitiatedDate");

  if (!initialAmountPaid) {
    return [undefined, undefined, paymentInitiatedDate || undefined];
  }
  if (transaction && transaction.paymentPlan) {
    const phases = transaction.paymentPlan.phases
    const [lastAmountPaid, lastDatePaid] = getLastPaymentPlanPayment(phases)
    if (lastDatePaid) {
      return [
        lastAmountPaid?.toString(),
        lastDatePaid,
        (paymentInitiatedDate && paymentInitiatedDate > lastDatePaid) ? paymentInitiatedDate : undefined
      ];
    }
  }
  const paymentDate = getFirstValueIfPresent(transaction, "paymentDate");
  return [initialAmountPaid, paymentDate ? paymentDate : undefined, undefined];
}

export const ToolProgressCard = (props: ToolProgressCardProps) => {
  const theme = useTheme();
  const buyerPersons = useGlobalStore((state) => state.buyerPersons);
  const buyerWithDetails = useGlobalStore((state) => state.buyerWithDetails);
  const { goodOption, transaction, user, isShowSeller, refetchPage } = props;

  const isDisableButtons = _.isUndefined(transaction) || [ProgressStatus.Abandoned, ProgressStatus.Canceled].includes(transaction?.status);
  const [amountPaid, paymentDate, paymentInitiatedDate] = getMostRecentPayment(transaction);
  const amountPaidNumeric = Number(amountPaid);

  // if amount paid is unset, we use undefined - if it represents NaN as a numeric (should not happen) we use the raw value, else fix at two decimals
  const cleanedAmountPaid = _.isUndefined(amountPaid) ? 
    undefined : Number.isNaN(amountPaidNumeric) ? 
    amountPaid : amountPaidNumeric.toFixed(2);

  let paymentMessage = paymentInitiatedDate ?
    `Processing as of ${moment(paymentInitiatedDate).format('MM/DD/YYYY')}` : (cleanedAmountPaid && paymentDate) ? 
    `$${cleanedAmountPaid} paid on ${moment(paymentDate).format('MM/DD/YYYY')}` : "";

  if (_.isUndefined(cleanedAmountPaid)) {
    const [inKindPaid, inKindDate] = getInKindPayment(transaction);

    if (inKindPaid && inKindDate) {
      const inKindNumeric = Number(inKindPaid);

      // if amount paid is unset, we use undefined - if it represents NaN as a numeric (should not happen) we use the raw value, else fix at two decimals
      const cleanedInKindPaid = _.isUndefined(inKindPaid) ? 
        undefined : Number.isNaN(inKindNumeric) ? 
        amountPaid : inKindNumeric.toFixed(2);

      paymentMessage = (cleanedInKindPaid && inKindDate) ? 
        `$${cleanedInKindPaid} in-kinded on ${moment(inKindDate).format('MM/DD/YYYY')} ` : "" ;
    }
  } 

  const sellerEmail = transaction?.process.email || 'no-reply@staclabs.io';

  const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-menu' });
  
  const emailDisabled = _.isUndefined(transaction?.externalId?.externalId) || _.isEqual('NOT_CONFIGURED', transaction?.externalId?.externalId)
  
  const possibleActiveWorkflows = transaction?.workflowItems?.filter(workflowItem => {
    return STARTED_STATUSES.includes(workflowItem.status);
  });
  const activeWorkflowHelperText = possibleActiveWorkflows?.length ?
    WORKFLOW_EXTERNAL_ID_TO_HELPER_TEXT[possibleActiveWorkflows[0].workflowExternalIds[0].externalId] : undefined;

  return (
    <Grid item md={12} lg={6} >
      <Card
        elevation={0} 
        sx={{ 
          backgroundColor: goodOption ? "white" : theme.palette.common.greyCardBackground, 
          color: theme.palette.common.navy,
          border: 1, 
          borderColor: theme.palette.common.greyCardBackground,
          height: "100%"
        }}
      >
        {goodOption && transaction && 
          <Fragment>
          <Grid container component={CardContent} sx={{height: "100%", }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant={'caption'}>
                  { goodOption.category }
                </Typography>
                <Typography variant={'subtitle1'}>
                  { goodOption.good.name }
                  { isShowSeller && (" - " + transaction.process.seller.name) }
                </Typography>
              </Grid>
              {hasAnyPermission(
                buyerPersons,
                buyerWithDetails.id,
                [Permission.ContactSeller, Permission.CancelTransaction]) &&
                <Grid item xs={1} display="flex" justifyContent="flex-end" >
                  <IconButton {...bindTrigger(popupState)}>
                    <MoreVert color="info" />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    transformOrigin={{vertical: 'top', horizontal: 'left'}}
                    elevation={1}
                  >
                    {hasPermission(
                      buyerPersons, buyerWithDetails.id, Permission.ContactSeller
                    ) && <MenuItem 
                        component="a"
                        disabled={emailDisabled}
                        href={`mailto:${sellerEmail}?subject=Reply to ${transaction?.externalId?.externalId}`}
                        target="_blank">
                      <Typography variant={'body2'}>Email {transaction?.process.seller.name}</Typography>
                    </MenuItem>}
                    {hasPermission(
                      buyerPersons, buyerWithDetails.id, Permission.CancelTransaction
                    ) && <TransactionCancelation
                      good={goodOption.good}
                      transaction={transaction}
                      user={user}
                      refetchPage={refetchPage}
                    />}
                  </Menu>
                </Grid>
              }
            </Grid>
            <Typography variant={'body2'} sx={{mt: 2, mb: 2}}>
              { goodOption.good.description }
            </Typography>
            {goodOption.good.isReferral ? 
              <Grid container direction="row" sx={{ justifyContent: "space-between"}}>
                <ReferralButton 
                  good={goodOption.good}
                  transaction={transaction}
                />
              </Grid> : <Grid container direction="row" sx={{ justifyContent: "space-between"}}>
                {hasPermission(
                  buyerPersons, buyerWithDetails.id, Permission.ViewTransactionHistory
                ) && <TransactionHistory good={goodOption.good} transaction={transaction} user={user} />}
                {hasPermission(
                  buyerPersons, buyerWithDetails.id, Permission.MakePayment
                ) && <PaymentButton 
                  transaction={transaction}
                  isDisabled={isDisableButtons || (!_.isUndefined(amountPaid) || !_.isUndefined(paymentInitiatedDate))}
                  text={paymentMessage}
                />}
                {hasPermission(
                  buyerPersons, buyerWithDetails.id, Permission.SignContract
                ) && <ContractButton
                  transaction={transaction}
                  user={user}
                  isDisabled={isDisableButtons}
                />}
              </Grid>
            }
          </Grid>
          <Grid item xs={12} sx={{ flexShrink: 100 }}>
          <Divider />
          <CardActions sx={{ p: 1, ":last-child": { pb: 1 } }}>
            <Grid container>
              <Grid item alignSelf="end">{ProgressStatusIcon[transaction.status]}</Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant={'overline'}>
                  {ProgressStatusText[transaction.status]}
                </Typography>
                {activeWorkflowHelperText && <Tooltip title={activeWorkflowHelperText} describeChild arrow>
                  <HelpOutline fontSize="small" />
                </Tooltip>}
              </Grid>
            </Grid>
          </CardActions>
          </Grid>
          </Fragment>
        }
        {!(goodOption && transaction) && <CardContent>
          <Dvr color="disabled" />
          <Typography variant={'body2'} color={theme.palette.common.darkGreyText}>
            You don't have any tools yet. Request access from the list below.
          </Typography>
        </CardContent>}
      </Card>
    </Grid>
  )
}