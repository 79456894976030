import { create } from 'zustand'

import { PersonBuyerState, createPersonBuyerSlice } from './personBuyerStore'
import { AccountState, createAccountSlice } from './accountStore'
import { SellerState, createSellerSlice } from './sellerStore'

type GlobalState = PersonBuyerState & AccountState & SellerState;

export const useGlobalStore = create<GlobalState>((...a) => ({
  ...createPersonBuyerSlice(...a),
  ...createAccountSlice(...a),
  ...createSellerSlice(...a),
}))