import { forwardRef, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  Button, Card, CardActions, CardContent, Grid, Stack, SxProps, Theme, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Dvr, Key, OpenInNew } from '@mui/icons-material';
import { User } from '@auth0/auth0-react';

import { Good, GoodOption, Person, Seller, Transaction } from "../../interfaces";
import { useLambdas } from '../../utils/lambdas';
import { useGlobalStore } from '../../store';
import { CircularProgressBackdrop, EmbedDialog } from '../common';

interface ToolPreviewCardProps {
  person: Person
  seller?: Seller
  user?: User
  goodOption?: GoodOption
  isRequestable?: boolean
  refetchPage?: Function
  sx?: SxProps<Theme>
  onClose?: Function
}
const ToolPreviewCard = forwardRef<HTMLButtonElement | null, ToolPreviewCardProps>(
  (props: ToolPreviewCardProps, buttonRef) => {
  const theme = useTheme();
  const person = useGlobalStore((s) => s.person);
  const buyer = useGlobalStore((state) => state.buyer);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [openReferralLink, setOpenReferralLink] = useState(false);

  const { goodOption, seller, refetchPage, isRequestable } = props;

  const [{ response: startTransactionResponse }, executePost] = useLambdas(
    "startTransaction",
    props.user,
    {
      sellerId: seller?.id,
      goodId: goodOption?.good.id,
      buyerId: buyer?.id,
      personId: props.person?.id
    }
  )

  if (startTransactionResponse && startTransactionResponse.status === 200) {
    if (refetchPage && !openReferralLink) {
      refetchPage();
    }
  }
  
  const startTransaction = async () => {
    return executePost().then(response => {
      const transaction: Transaction = response.data;
      const good: Good = transaction.process.bundle.goods[0];
      if (good.isReferral) {
        if (good.isReferralInNewTab) {
          window.open(good.referralLink, '_blank');
        } else {
          setReferralLink(`${good.referralLink}${transaction.id}`);
          setOpenReferralLink(true);
        }
      }
      return transaction;
    })
  }

  const requestAccess = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (props.onClose) {
      props.onClose();
    }

    ReactGA.event({
      category: "request_access",
      action: "click",
      label: goodOption?.good.name || "",
    });

    startTransaction().catch((error: any) => {
      console.error('Submit failed!', error);
    });
  }

  const isNotLive = goodOption?.good.name.includes("COMING-SOON") &&
    !person?.email?.includes("@staclabs.io");
  
  return (
    <Grid item md={12} lg={6} sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]} >
      <Card
        elevation={0} 
        sx={{ 
          backgroundColor: goodOption ? "white" : theme.palette.common.greyCardBackground,
          color: theme.palette.common.navy,
          border: 1, 
          borderColor: theme.palette.common.navy,
          height: "100%",
        }}
      >
        {goodOption &&
        <CardContent>
          <Stack direction="row" sx={{display: 'flex', alignItems: 'center', justifyContent:"space-between"}}>
            <Typography variant={'overline'}>
              { goodOption.category }
            </Typography>
            <img
              src={`${process.env.REACT_APP_ASSETS_CDN_URL}/${goodOption.good.logo}`}
              className="Tool-logo"
              alt={`logo for ${goodOption.good.name}`}
            />
          </Stack>
          <Typography variant={'h6'}>
            { goodOption.good.name }
          </Typography>
          <Typography variant={'body2'} sx={{ mt: 1 }}>
            { goodOption.good.description }
          </Typography>
          {goodOption.good.isReferral && isRequestable && 
            <Typography variant={'body2'} sx={{fontWeight: "600"}} paddingTop={1}>
              We are proud to partner with this provider. 
              By selecting this tool, you will be redirected to their website.
            </Typography>}
        </CardContent>}
        {!goodOption && <CardContent>
          <Dvr color="disabled" />
          <Typography variant={'body2'} color={theme.palette.common.darkGreyText}>
            You've purchased all our tools!
          </Typography>
        </CardContent>}
        { !isRequestable && goodOption?.good.website && 
          <CardActions>
            <Button
              href={goodOption.good.website}
              rel="noopener"
              target="_blank"
              size="small"
              endIcon={<OpenInNew />}>Visit website</Button>
          </CardActions>}
          <CircularProgressBackdrop open={isSubmitting} />
          <CardActions >
          { goodOption && isRequestable &&
            <Button
              ref={buttonRef}
              onClick={requestAccess}
              size="small"
              variant="contained"
              disableElevation
              disabled={isSubmitting || isNotLive}
              endIcon={<Key />}
              sx={{ml: 1, mb: 1, pl: 2, pr: 2}}
            >
              Request access
            </Button>
          }
          { goodOption && goodOption.learnMoreLink &&
            <Button
              href={goodOption.learnMoreLink}
              rel="noopener"
              target="_blank"
              size="small"
              variant="contained"
              disableElevation
              sx={{ml: 1, mb: 1, pl: 2, pr: 2}}
              endIcon={<OpenInNew />}
            >
              Learn More
            </Button>
          }
          </CardActions>
      </Card>
      <EmbedDialog
        link={referralLink}
        title="referral-link"
        open={openReferralLink}
        setOpen={setOpenReferralLink}
      />
    </Grid>
  )
});

export default ToolPreviewCard;