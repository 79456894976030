import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';

import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { getAccount } from '../../queries';

import { shallow } from 'zustand/shallow';

import shortLogo from '../../staclabs_shortlogo.svg';

import { useGlobalStore } from "../../store";
import { BuyerSelectDialog } from "./BuyerSelectDialog";
import { CommitteeMenu } from "./CommitteeMenu";
import { CommitteeNavBar } from "./CommitteeNavBar";

import { useTheme } from '@mui/material/styles';

const Header = () => {
  const { 
    user, isAuthenticated, 
    loginWithRedirect, logout,
  } = useAuth0();

  const { buyer, person, buyerPersons } = useGlobalStore(
    (state) => ({ 
      buyer: state.buyer, person: state.person, buyerPersons: state.buyerPersons
    }),
    shallow
  );
  const updatePerson = useGlobalStore((state) => state.updatePerson);
  const updateRefetch = useGlobalStore(state => state.updateRefetch);

  const theme = useTheme();

  const email = user?.email || '';

  const { loading: isAccountLoading, data: accountData, refetch } = useQuery(
    getAccount,
    { 
      variables: { email: email },
      skip: email === ''
    }
  );

  const [ isSmallMenuOpen, setSmallMenuOpen ] = useState(false);

  useEffect(() => {
    updatePerson(accountData?.account?.person)
    updateRefetch(refetch)
  }, [ accountData, updatePerson, refetch, updateRefetch ])

  const [ isChooseCommittee, setIsChooseCommittee ] = useState(false);

  const handleClose = () => {
    setIsChooseCommittee(false);
    setSmallMenuOpen(false);
  }

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const isRenderSmallMenu = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return (
    <Grid container spacing={2}
      sx={{ 
        p: 3,
        backgroundColor: '#2861E2'
      }} 
      textAlign="left" alignItems="center">
      <Grid item xs>
        <Link href='/' color="#FFFFFF" variant="h6" underline="none">
          <img src={shortLogo} alt="logo" className="short-logo" height="15px" />
          DemLaunch 
        </Link>
      </Grid>
      {!isAuthenticated &&
        <Grid item>
          <Button
            id="qsLoginBtn"
            variant="contained"
            className="btn-margin"
            size="small"
            disableElevation
            color="tertiary"
            fullWidth
            onClick={() => loginWithRedirect()}
          >
            <Typography variant={'subtitle2'}>
              Login
            </Typography>
          </Button>
        </Grid>
      }
      { (isAuthenticated && isRenderSmallMenu) && 
        <Grid item display={'block'}>
          <CommitteeMenu
            isOpen={isSmallMenuOpen}
            setIsOpen={setSmallMenuOpen}
            logoutWithRedirect={logoutWithRedirect}
            setChooseCommittee={setIsChooseCommittee}
          />
        </Grid>
      }
      { (isAuthenticated && ! isRenderSmallMenu) && 
        <Grid item container
          sm={6}
          className="nav-container"
          textAlign={'right'}
          alignItems="center"
          sx={{ justifyContent: "space-between"}}
        >
          <CommitteeNavBar
            logoutWithRedirect={logoutWithRedirect}
          />
        </Grid>}
      { (isAuthenticated && isChooseCommittee) && <Grid container>
        <Grid item xs={2}>
          <BuyerSelectDialog 
            isOpen={isChooseCommittee}
            handleClose={handleClose}
            buyer={buyer}
            buyerPersons={buyerPersons}
            person={person}
            isAccountLoading={isAccountLoading}
          />
        </Grid>
      </Grid>}
    </Grid>
  )
}

export default Header;