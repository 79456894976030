import _ from "lodash";
import { User } from "@auth0/auth0-react";
import { useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";

import { BuyerPerson, PersonValue, } from "../../interfaces";
import { EditPerson, EditPersonType } from "../user/EditPerson";
import { useLambdas } from "../../utils/lambdas";
import { setPersonDataValue } from "../../utils/person";

interface EditPersonSubsectionProps {
  isEditMode?: boolean
  buyerPerson?: BuyerPerson
  buyerId?: number
  user?: User
}

export const EditPersonSubsection = (props: EditPersonSubsectionProps) => {
  const [ hasAccount, setHasAccount ] = useState(false);
  const [ personValues, setPersonValues ] = useState<PersonValue>({
    firstName: props.buyerPerson?.person.firstName || '',
    middleName: props.buyerPerson?.person.middleName || '',
    lastName: props.buyerPerson?.person.lastName || '',
    email: props.buyerPerson?.person.email || '',
    emailOptIn: props.buyerPerson?.person.emailOptIn || true,
    phone: props.buyerPerson?.person.phone || '',
    phoneOptIn: props.buyerPerson?.person.phoneOptIn || true,
    role: props.buyerPerson?.role?.name || '',
    permissionGroupId: props.buyerPerson?.permissionGroup.id
  });

  const [, executePost] = useLambdas(
    props.isEditMode ? "updatePerson" : "addPerson",
    props.user,
    {
      person: props.isEditMode ? 
        _.omit({
          ...personValues,
          id: props.buyerPerson?.person.id,
        }, ['role', 'permissionGroup']) 
        : 
        personValues, 
      buyer: { id: props.buyerId },
      hasAccount: hasAccount
    }
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonValues(prevState => ({
      ...prevState,
      ...setPersonDataValue(e)
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    const { name, value } = e.target
    setPersonValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAccountChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasAccount(e.target.checked);
  };

  return (
    <Box>
      {props.isEditMode ? <EditPerson
        sendToHandler={executePost}
        handleInputChange={handleInputChange}
        editMode={EditPersonType.buyerEdit}
        sectionTitle="Edit user"
        isLoaded={true}
        personValues={personValues}
        handleSelectChange={handleSelectChange}
      /> :
      <EditPerson
        sendToHandler={executePost}
        handleInputChange={handleInputChange}
        editMode={EditPersonType.buyerAdd}
        sectionTitle="Add user"
        isLoaded={true}
        personValues={personValues}
        hasAccount={hasAccount}
        handleAccountChecked={handleAccountChecked}
        handleSelectChange={handleSelectChange}
      />}
    </Box>
  );
}