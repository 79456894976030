
import { gql } from '@apollo/client';

export const getAccount = gql`
  query getAccount($email: String) {
    account(email: $email) {
      person {
        id
        firstName
        middleName
        lastName
        email
        emailOptIn
        phone
        phoneOptIn
        defaultBuyerId
        buyers {
          buyer { id name }
          permissionGroup { id name permissions isSuperuser distributableGroups {id, name} }
        }
      }
    }
  }`;