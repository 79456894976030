
import { useNavigate } from "react-router-dom";

import StartIcon from '@mui/icons-material/Start';
import { Button, ButtonGroup, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { OptionTemplate } from '../../interfaces';
import { useGlobalStore } from "../../store";

const blueColor = { color: 'navy' };

function SellerInfoCard(props: { template: OptionTemplate }) {
  const buyer = useGlobalStore((state) => state.buyer);
  const { template } = props;
  
  const updateSeller = useGlobalStore((state) => state.updateSeller);

  const navigate = useNavigate();
  const theme = useTheme();

  return(
    <Card
      elevation={0} 
      sx={{ 
        backgroundColor: "white", 
        color: theme.palette.common.navy,
        border: 1, 
        borderColor: theme.palette.common.navy,
        height: "100%"
    }}>
      <CardContent>
        <Typography variant={'h6'}>
          { template.seller.name }
        </Typography>
        <Typography variant={'body2'}>
          Click below to get tools from the { template.seller.name }
        </Typography>
      </CardContent>
      <CardActions>
        <ButtonGroup variant='text'>
          <Button startIcon={<StartIcon />} sx={{ ...blueColor }} onClick={() => {
              const sellerCode = template.seller.externalId.externalId
              updateSeller(template.seller, sellerCode, template);
              navigate(`/${sellerCode}`);
            }}>
            Set up {buyer.id ? "a new" : "my"} campaign
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  )
}

export default SellerInfoCard;
