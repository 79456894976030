import { TextField, FormControl } from '@mui/material';
import { BuyerOptionProps } from '.';

import React, { useState } from 'react';


export default function Text(props: BuyerOptionProps) {
  const [ textValue, setTextValue ] = useState(props.currentValue || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);

    if (props.onDetailChange) {
      props.onDetailChange(
        props.optionKey,
        e.target.value,
        props.isEditMode ? props.buyerDetailId : props.id
      );
    }
  }


  return (
    <FormControl fullWidth>
      <TextField 
        required={props.isRequired}
        value={textValue}
        disabled={props.isDisabled}
        helperText={textValue.length >= 255 ? 
          "Maximum length of field is 255 characters. Please shorten." :
          props.description}
        inputProps={{ maxLength: 255 }}
        onChange={handleChange}
        label={props.label} />
    </FormControl>
  );
}
