import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { EditPerson, EditPersonType } from "./EditPerson";
import { PersonValue } from '../../interfaces';
import { getAccount } from "../../queries";
import { useLambdas } from "../../utils/lambdas";
import { setPersonDataValue } from "../../utils/person";

export function UserSelfEditPage() {
  const navigate = useNavigate();

  const { user, isLoading: isAccountLoading } = useAuth0();
  const [ personValues, setPersonValues ] = useState<PersonValue>({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    emailOptIn: true,
    phone: '',
    phoneOptIn: true
  });

  const [, executePost] = useLambdas(
    "updatePerson",
    user,
    { person: personValues }
  )

  useEffect(() => {
    if (!user && !isAccountLoading) {
      navigate("/new")
    }
  }, [ user, isAccountLoading, navigate ])

  const { data: loadedPerson } = useQuery(
    getAccount,
    { 
      variables: { email: user?.email },
      onCompleted(data) {
        const { firstName, middleName, lastName, email, 
                emailOptIn, phone, phoneOptIn, id } = data?.account?.person
        setPersonValues({
          firstName,
          middleName,
          lastName,
          email,
          emailOptIn,
          phone,
          phoneOptIn,
          id
        })
      },
    },
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonValues(prevState => ({
      ...prevState,
      ...setPersonDataValue(e)
    }));
  };

  return <Box maxWidth="95%" sx={{ m: 4 }}>
    <EditPerson
      sendToHandler={executePost}
      handleInputChange={handleInputChange}
      editMode={EditPersonType.selfEdit}
      sectionTitle="My profile"
      isLoaded={loadedPerson}
      personValues={personValues}
    />
  </Box>
}