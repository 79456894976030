import { useQuery } from "@apollo/client";
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, Location } from "react-router-dom";

import { CancelOutlined, SentimentVeryDissatisfied } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { CircularProgressBackdrop, StyledDialogActions } from './common';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Seller, Transaction } from '../interfaces';
import { getToolsByBuyer } from '../queries';
import { useGlobalStore } from "../store";
import { ToolsTabPanel } from './tools/ToolsTabPanel';
import { isNewBuyerCreatedLocation } from '../interfaces/locations';

function Home() {
  const navigate = useNavigate();
  const buyer = useGlobalStore((state) => state.buyer);
  const updateBuyerWithDetails = useGlobalStore((state) => state.updateBuyerWithDetails);
  const { user, isLoading: isAccountLoading } = useAuth0();

  const seller = useGlobalStore((s) => s.seller);

  const location: Location = useLocation();
  const locationDetails = isNewBuyerCreatedLocation(location.state) ? location.state : undefined;
  const isNewBuyerCreated = _.isUndefined(locationDetails?.newBuyerCreated) ? false : locationDetails?.newBuyerCreated;

  const [showNewBuyerPopup, setShowNewBuyerPopup] = useState(_.isUndefined(isNewBuyerCreated) ? false : isNewBuyerCreated);

  const { loading: isQueryLoading, error, data: queryData, refetch } = useQuery(
    getToolsByBuyer,
    {
      
      variables: { id: buyer.id },
      skip: _.isEmpty(buyer),
      pollInterval: 120*1000
    },
  );

  useEffect(() => {
    if (!isQueryLoading && !isAccountLoading && (!user || _.isUndefined(buyer))) {
      navigate("/new")
    }  
  }, [isQueryLoading, user, buyer, isAccountLoading, navigate]);
  
  useEffect(() => {
    updateBuyerWithDetails(queryData?.buyer);
  }, [queryData, updateBuyerWithDetails])

  const handleClose = () => {
    setShowNewBuyerPopup(false);
    window.history.replaceState({}, document.title);
  }
  
  if (isQueryLoading && error) {
    return (
      <Grid container spacing={2} sx={{ m: 3 }} alignItems="center" textAlign="center">
        <Grid item xs={10}>
          <Typography variant="h6">
            There was an error loading your tools.
            Please refresh your page and try again, or contact { seller && seller.name ? seller.name : 'your state party' }.
          </Typography>
          <SentimentVeryDissatisfied color="error" sx={{ fontSize: 100 }} />
        </Grid>
      </Grid>
    )
  }

  let transactions: Transaction[] = queryData?.buyer.transactions;
  let sellers: Seller[] = queryData?.buyer.sellers;

  return (
    <Fragment>
      <Dialog
        open={! (isQueryLoading || isAccountLoading || _.isEmpty(buyer)) && showNewBuyerPopup}
        maxWidth={'sm'}
        onClose={handleClose}
      >
        <DialogTitle sx={{ m: 0, pt: 2, pr: 2, pl: 2, pb: 0 }}>
          Account Created
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.common.navy,
          }}
        >
          <CancelOutlined />
        </IconButton>
        <DialogContent>
          <Typography>Thanks for creating an account! You're ready to make your first request{ seller && seller.name ? ` from ${seller.name}` : ''}.</Typography>
        </DialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose}
            sx={{ color: 'white' }}
            startIcon={<ArrowForwardIcon/>}
          >
            Continue to select a tool
          </Button>
        </StyledDialogActions>
      </Dialog>
      <CircularProgressBackdrop 
        open={isQueryLoading || isAccountLoading || _.isEmpty(buyer)} />
      <Stack
        direction='row'
        sx={{pt: 3, pl: 3, pr: 3, mb: 1, display: 'flex', alignItems: 'center' }}
      >
        <Typography variant="h5">
          {buyer.name}
        </Typography>
      </Stack>
      <ToolsTabPanel
        transactions={transactions}
        sellers={sellers}
        user={user}
        refetchPage={refetch}
      />
    </Fragment>
  );
}

export default Home;
