import { StyledDialogActions } from '.';
import { Button, Dialog, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface EmbedDialogProps {
  link: string
  title: string
  open: boolean
  setOpen: Function
}

const EmbedDialog = (props: EmbedDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return <Dialog
    open={props.open}
    onClose={() => props.setOpen(false)}
    fullScreen={fullScreen}
    maxWidth="md"
    fullWidth
  >
    <iframe
      title={props.title}
      src={props.link}
      width="100%" height={fullScreen ? "100%" : "500"}
      style={{
        background: "transparent",
        border: "1px solid #ccc",
      }}
    ></iframe>
    <StyledDialogActions>
      <Button
        variant="text"
        onClick={() => props.setOpen(false)}
        sx={{ color: 'white' }}
      >
      <Typography variant="body1">
        Close
      </Typography>
      </Button>
    </StyledDialogActions>
  </Dialog>
}

export default EmbedDialog;