import { Box } from "@mui/material";
import { useGlobalStore } from "../../store";
import _ from "lodash";

export const SellerLogo = (props: { large?: boolean }) => {

  const sellerCode = useGlobalStore((state) => state.sellerCode);

  return (
    <Box display="flex" justifyContent="center" alignItems="top" height="100%">
      { ! _.isEmpty(sellerCode) && 
        <img
          src={`https://stac-commons.s3.amazonaws.com/state-party-logos/${sellerCode}.png`}
          className={props.large ? "Seller-logo-large": "Seller-logo"}
          alt="logo"
        />
      }
    </Box>
  )

}
