import { useState } from 'react';
import { DateTime } from 'luxon';
import { BuyerOptionProps } from '.';
import { BasicDatePicker } from '../../common';

export default function DateComponent(props: BuyerOptionProps) {
  const [value, setValue] = useState<DateTime | null>(
    props.currentValue ? DateTime.fromISO(props.currentValue) : null,
  );

  const handleChange = (newValue: DateTime | null) => {
    setValue(newValue);

    try {
      const newStringVal = newValue?.toString().substring(0, 10);
      if (props.onDetailChange) {
        props.onDetailChange(props.optionKey, newStringVal, props.isEditMode ? props.buyerDetailId : props.id);
      }
    } catch (error) {
      return
    }
  };

  return <BasicDatePicker
    label={props.label}
    value={value}
    onChange={handleChange}
    required={props.isRequired}
    disabled={props.isDisabled}
  />;
}
