import { InputLabel, MenuItem, Select, SelectChangeEvent, FormControl, FormHelperText } from '@mui/material';
import { BuyerOptionProps, isGroupBuyerOptionValues } from '.';
import { useEffect, useState } from 'react';

export default function SingleSelectWithoutOther(props: BuyerOptionProps) {

  const radioOptionVals = isGroupBuyerOptionValues(props.value) ? props.value : undefined;
  const vals = radioOptionVals ? radioOptionVals.options : [''];
  const defaultValue = (radioOptionVals && radioOptionVals.default) ? radioOptionVals.default : vals[0]
  const [selectedValue, setSelectedValue] = useState(props.currentValue || defaultValue);
  const [isDefaultValue, setIsDefaultValue] = useState(true);

  useEffect(() => {
    if (isDefaultValue && !props.isEditMode) {
      props.onDetailChange(props.optionKey, defaultValue, props.id);
      setIsDefaultValue(false);
    }
  }, [isDefaultValue, defaultValue, props])
  
  const handleSelectChange = (e: SelectChangeEvent) => {
    setSelectedValue(e.target.value);

    if (props.onDetailChange) {
      props.onDetailChange(props.optionKey, e.target.value, props.isEditMode ? props.buyerDetailId : props.id);
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel disabled={props.isDisabled}>{props.label}</InputLabel>
      <Select
        sx={{
          align: 'left'
        }}
        label={props.label} // creates space in the box outline for the InputLabel
        value={selectedValue}
        disabled={props.isDisabled}
        onChange={handleSelectChange}
        required={props.isRequired}
      >
        {
          vals.map(value => {
            return <MenuItem key={value} value={value}>{value}</MenuItem>
          })
        }
      </Select>
      <FormHelperText>{props.description}</FormHelperText>
    </FormControl>
  );
}

