import { useMutation } from "@apollo/client";
import { Check } from "@mui/icons-material";
import { Box, Grid, MenuItem, Tooltip, Typography } from '@mui/material';
import { Buyer, BuyerPerson } from '../../interfaces';
import { updatePerson } from "../../queries";
import { useGlobalStore } from "../../store";
import { useNavigate } from "react-router-dom";

interface BuyerSelectProps {
  buyerPersons: BuyerPerson[]
  personId: number
  currentBuyer: Buyer
  handleClose: Function
}

export const BuyerSelect = (props: BuyerSelectProps) => {
  const [ saveDefaultBuyer] = useMutation(updatePerson);
  const navigate = useNavigate();

  const updateBuyer = useGlobalStore((s) => s.updateBuyer);

  return (
    <Box sx={{mt: 1}}>
      {props.buyerPersons.map((buyerPerson) => (
        <Tooltip title="Click to select" describeChild arrow key={buyerPerson.buyer.id}>
          <MenuItem
            onClick={() => {
              updateBuyer(buyerPerson.buyer);
              props.handleClose();

              saveDefaultBuyer({
                variables: {
                  person: {
                    id: props.personId,
                    defaultBuyerId: buyerPerson.buyer.id
                  }
                }
              });
              navigate(`/`)
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={1} sx={{ mr: 2, display: { xs: 'block', sm: 'none' }}}>
                {props.currentBuyer.id === buyerPerson.buyer.id && <Check />}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{buyerPerson.buyer.name}</Typography>
              </Grid>
              <Grid item xs={6}
                justifyContent="flex-end"
                sx={{ display: { xs: 'none', sm: 'flex' }}}
              >
                {props.currentBuyer.id === buyerPerson.buyer.id && <Check />}
              </Grid>
            </Grid>
          </MenuItem>
        </Tooltip>
      ))}
  </Box>
  )
}