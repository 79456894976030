import { Backdrop, Box, Grid, IconButton, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { User } from '@auth0/auth0-react';

import { useGlobalStore } from "../../store";

import { OptionTemplate, Seller } from '../../interfaces';
import ToolPreviewCard from './ToolPreviewCard';
import { useEffect, useRef, useState } from 'react';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import { ArrowBack, ArrowUpward, CancelOutlined } from '@mui/icons-material';

interface AvailableToolsProps {
  hasRequestedTools?: boolean
  seller?: Seller
  user?: User
  optionTemplate?: OptionTemplate
  isShowSeller?: boolean
  requestedGoodIds?: number[]
  canceledGoodIds?: number[]
  isRequestable?: boolean
  refetchPage?: Function
}

export const AvailableTools = (props: AvailableToolsProps) => {
  const { seller, requestedGoodIds, canceledGoodIds, user, isRequestable, refetchPage, hasRequestedTools } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const toolRef = useRef<HTMLButtonElement>(null);

  const theme = useTheme();
  const { person } = useGlobalStore();
  const popupState = usePopupState({ variant: 'popper', popupId: 'simple-popover' })

  useEffect(() => {
    if (!hasRequestedTools && toolRef && toolRef.current) {
      popupState.open(toolRef.current)
      setAnchorEl(toolRef.current)
    }
  /** 
   * JR 2024-01-05: this is necessary to force this to happen only one time on component mount.
   * No dependency array or including popupState in the dependency array leads to infinite render loops.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (anchorEl && toolRef && toolRef.current) {
      toolRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      })
    }
  }, [anchorEl]);

  const onClose = () => {
    setAnchorEl(null);
    popupState.close();
  };

  const id = Boolean(anchorEl) ? 'simple-popover' : undefined;

  const optionTemplate = props.optionTemplate || props.seller?.optionTemplates.find(optionTemplate => {
    return optionTemplate.isActive
  })

  const goodOptions = optionTemplate?.goodOptions?.filter(goodOption => {
    const { id } = goodOption.good;
    const requestedIncludesId = requestedGoodIds?.includes(id);
    return !requestedIncludesId || (requestedIncludesId && canceledGoodIds?.includes(id));
  })

  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ backgroundColor: theme.palette.common.yellowBackground, pb: 4, pt:2, pr: 5, pl: 5}}>
      <Typography variant={'h5'} sx={{ paddingBottom: 2 }}>
        Available Tools
        { props.isShowSeller && (" - " + props.seller?.name) }
      </Typography>
      <Backdrop 
        open={popupState.isOpen} 
        onClick={onClose}
      />
      <Grid container spacing={2}>
        { goodOptions?.length === 0 ? <ToolPreviewCard person={person}/> : 
          goodOptions?.map((goodOption, index) => (
          <ToolPreviewCard
            key={goodOption.id}
            person={person}
            seller={seller}
            user={user}
            goodOption={goodOption}
            isRequestable={isRequestable}
            refetchPage={refetchPage}
            onClose={onClose}
            {... (index === 0) ? {ref: toolRef} : {} }
            {... popupState.isOpen ? { sx: {zIndex: (theme) => theme.zIndex.drawer + 2 }} : {}}
          />
        ))}
      </Grid>
      { anchorEl && <Popper
        id={id}
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 3,
          width: '50%'
        }}
        disablePortal={false}
        {...bindPopper(popupState)} 
        placement={isMediumBreakpoint ? 'bottom-start' : 'right-start'}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'document',
              padding: 8,
            }, 
          }, 
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: anchorEl,
            },
          },
        ]}
      >
        <Paper
          sx={{
            backgroundColor: theme.palette.common.navy,
            color: 'white',
            borderRadius: '4px'
          }}
        >
          <Stack>
            <Stack direction="row" sx={{display: 'flex', alignItems: 'center', justifyContent:"space-between"}}>
              <IconButton aria-label='left-arrow' sx={{ color: 'white'}}>
                {isMediumBreakpoint ? <ArrowUpward/> : <ArrowBack/> }
              </IconButton>
              <IconButton aria-label='close' onClick={onClose} sx={{ color: 'white'}}>
                <CancelOutlined/>
              </IconButton>
            </Stack>
            <Typography variant='h6' sx={{ p: 1 }}>Select a tool to begin</Typography>
            <Typography sx={{ p: 1 }} >Get started today by selecting a tool{ seller && seller.name ? ` from ${seller.name}` : ''}!</Typography>
          </Stack>
        </Paper>
      </Popper>}
    </Box>
  )
}