import { gql } from '@apollo/client';

export const getAllActiveTemplates = gql`
  query {
    optionTemplates(filter: {isActive: true}) {
      id
      isActive
      seller {
        id
        name
        sellerPageMessage
        externalId {
          externalId
        }
        districtGroups {
          id
          displayName
          isActive
          boundaries {
            id
            eeDistrictId
            eeDistrictName
          }
        }
      }
      buyerOptions {
        id
        key
        label
        description
        value
        inputType
        detailType
        uiElement
        isRequired
        uiOrder
      }
      goodOptions {
        id
        category
        good {
          id
          name
          logo
          description
          isReferral
        }
      }
    }
  }
`;

export const getStateTemplate = gql`
  query getStateTemplate($filter: OptionTemplateFilter) {
    optionTemplates(filter: $filter) {
      id
      isActive
      seller {
        id
        name
        website
        sellerPageMessage
        districtGroups {
          id
          displayName
          isActive
          boundaries {
            id
            eeDistrictId
            eeDistrictName
          }
        }
      }
      buyerOptions {
        id
        key
        label
        description
        value
        inputType
        detailType
        uiElement
        isRequired
        uiOrder
      }
      goodOptions {
        id
        category
        good {
          id
          name
          logo
          description
          isReferral
        }
      }
    }
  }
`;