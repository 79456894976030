import { useState, SyntheticEvent } from 'react';
import { Autocomplete, TextField, FormControl } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { BuyerOptionProps } from '.';
import { useGlobalStore } from '../../../store';

const filter = createFilterOptions<FlatBoundary>();

interface FlatBoundary {
  id: number
  eeDistrictId: number
  eeDistrictName: string
  districtGroup?: { id: number, displayName: string }
}

export default function EEDistrict(props: BuyerOptionProps) {
  const [ value, setValue ] = useState<FlatBoundary | null>(null);
  const [ inputValue, setInputValue ] = useState('');

  const seller = useGlobalStore((s) => s.seller);
  
  const districtGroups = seller.id ? seller.districtGroups : props.districtGroups;
  if (!districtGroups) {
    return null
  }

  const boundaries: FlatBoundary[] = districtGroups.filter(districtGroup => {
    return districtGroup.isActive;
  }).map(districtGroup => {
    return districtGroup.boundaries?.map(boundary => {
      return {
        ...boundary,
        districtGroup: {
          id: districtGroup.id,
          displayName: districtGroup.displayName,
        }
      }
    })
  }).flat()

  const existingBoundary = boundaries.filter(
    boundary => boundary.eeDistrictId === Number(props.externalId)
  )[0];

  const handleChange = (
    event: SyntheticEvent<Element, Event>, newValue: FlatBoundary | null
  ) => {
    setValue(newValue);
    
    if (props.onDetailChange) {
      props.onDetailChange(
        props.optionKey,
        newValue?.eeDistrictName,
        props.isEditMode ? props.buyerDetailId : props.id,
        newValue?.eeDistrictId !== -1 ? {
          externalId: String(newValue?.eeDistrictId),
          externalIdType: 'EEDistrictId'
        } : undefined
      );
    }
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        value={value || (props.isEditMode ? existingBoundary : null)}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={boundaries}
        renderOption={(props, option) => <li {...props}>{
          option.districtGroup?.displayName ? 
          `${option.districtGroup?.displayName}: ${option.eeDistrictName}` :
          `Add ${option.eeDistrictName} (note: will not allow for certain reports)`
        }</li>}
        getOptionLabel={(option) => {
          return option.eeDistrictName ?
            option.districtGroup?.displayName ?
            `${option.districtGroup?.displayName}: ${option.eeDistrictName}` :
            option.eeDistrictName : '';
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id || value.id === -1
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          const isExisting = options.some((option) => {
            return inputValue === option.eeDistrictName
          });
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              id: -1,
              eeDistrictId: -1,
              eeDistrictName: `${inputValue}`,
            });
          }
  
          return filtered;
        }}
        renderInput={(params) => <TextField {...params}
          required={props.isRequired}
          disabled={props.isDisabled}
          label={props.label}
          helperText={props.description}
        />}
      />
    </FormControl>
  );
}
