import { sanitizeValueIfEmail } from "./email";

export function setPersonDataValue(
  e: React.ChangeEvent<HTMLInputElement>
): object {
  const { name, value, checked } = e.target;

  return {
    [name]: ['emailOptIn', 'phoneOptIn'].includes(name) ? checked 
          : sanitizeValueIfEmail(name, 'email', value)
  }
}
