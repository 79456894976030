import { OptionTemplate } from '../../interfaces';

export * from './AllCampaignDetails';

export interface BuyerDetailsLocation {
  optionTemplate: OptionTemplate
  showStaffMessage?: boolean
}

export function isBuyerDetailsLocation(item: BuyerDetailsLocation | unknown): item is BuyerDetailsLocation {
  if (!item) {
    return false
  }
  if ((item as BuyerDetailsLocation).optionTemplate) {
    return true;
  }

  return false;
}