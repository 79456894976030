import _ from 'lodash';

import { Transaction } from '../../interfaces';
import { getFirstValueIfPresent } from '../../utils/transactions'
import { Button } from '@mui/material';

interface PaymentProps {
  transaction: Transaction | undefined
  isDisabled?: boolean
  text?: string 
}

export function PaymentButton(props: PaymentProps) {

  const transaction = props.transaction
  const paymentUrl = getFirstValueIfPresent(transaction, "paymentUrl");
  const text = props.text || "Pay for access"
  const isDisabled = Boolean(props.isDisabled || _.isUndefined(paymentUrl) || transaction?.completionDate)

  if (!transaction?.hasPayment) {
    return (<div/>)
  }

  return (
    <Button 
      href={paymentUrl || ""}
      target="_blank" 
      variant="contained"
      size="small"
      disableElevation
      disabled={ isDisabled }
      sx={{ mb: 1, pl: 2, pr: 2 }} >
    { text }
  </Button>
  )
}
