import { BuyerOption, PersonBuyerOptionValues, GroupBuyerOptionValues, PersonValue } from '../../../interfaces';

export interface BuyerOptionProps extends Omit<BuyerOption, 'key'> {
  optionKey: string             // jrose 10 Aug 22: Necessary because 'key' is a protected property
  onDetailChange: Function
  isEditMode?: boolean
  currentValue?: any
  isDisabled?: boolean
  buyerDetailId?: number
  externalId?: string
  firstPersonKey?: string
  firstPersonLabel?: string
  firstPersonValue?: PersonValue
}

export function isPersonBuyerOptionValues(item: PersonBuyerOptionValues | unknown): item is PersonBuyerOptionValues {
  if ((item as PersonBuyerOptionValues).fields) {
    return true;
  }

  return false;
}

export function isGroupBuyerOptionValues(item: GroupBuyerOptionValues | unknown): item is GroupBuyerOptionValues {
  if ((item as GroupBuyerOptionValues).options) {
    return true;
  }

  return false;
}

export * from './Checkbox';
export * from './Date';
export * from './DateTime';
export * from './MultiSelect';
export * from './Number';
export * from './Paragraph';
export * from './Person';
export * from './RadioGroup';
export * from './SingleSelectWithOther';
export * from './SingleSelectWithoutOther';
export * from './Text';
export * from './Url';
export * from './BuyerOptionRegistry';