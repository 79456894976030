import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Drawer, IconButton, ListItem, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { shallow } from 'zustand/shallow';
import { useGlobalStore } from "../../store";


interface CommitteeMenuProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  logoutWithRedirect: () => void;
  setChooseCommittee: (state: boolean) => void;
}

export const CommitteeMenu = (props: CommitteeMenuProps) => {

  const { isOpen, logoutWithRedirect, setChooseCommittee, setIsOpen } = props;

  const { buyer, person } = useGlobalStore(
    (state) => ({ 
      buyer: state.buyer, person: state.person
    }),
    shallow
  );

  const navigate = useNavigate();

  const navigateAndClose = (path: string) => {
    setIsOpen(false);
    navigate(path);
  }
  
  const closeAndLogout = () => {
    setIsOpen(false);
    logoutWithRedirect();
  }

  const onClose = (event: any) => {
    setIsOpen(false);
  }

  return(
    <React.Fragment>
      <IconButton size="small"
        onClick={() => setIsOpen(! isOpen)}
        color="tertiary"
      >
        <MenuIcon fontSize="medium"/>
      </IconButton>
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
      >
        <Box sx={{ m: 2 }}>
          {person && <Box>{(person.firstName || person.lastName) &&
            <ListItem>
              <Typography variant={'h6'}>
                {`${person?.firstName} ${person?.lastName}`}
              </Typography>
            </ListItem>}
          <MenuItem onClick={() => navigateAndClose(`./user`)}>
            <Typography variant={'body1'}>
              My profile
            </Typography>
          </MenuItem>
          <Divider />
          {buyer?.name &&
            <ListItem>
              <Typography variant={'h6'}>
              {buyer.name}
              </Typography>
            </ListItem>}
          <MenuItem onClick={() => setChooseCommittee(true)}>
            <Typography variant={'body1'}>
              Switch my committee
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => navigateAndClose(`./new`)}>
            <Typography variant={'body1'}>
              Create a new committee
            </Typography>
          </MenuItem>
          <Divider />
          </Box>}
          <MenuItem onClick={closeAndLogout}>
            <Typography variant={'body1'}>
              Log out
            </Typography>
          </MenuItem>
        </Box>
      </Drawer>
    </React.Fragment>
  )
}