import { gql } from '@apollo/client';

export const updatePerson = gql`
  mutation updatePerson($person: PersonUpdateInput!) {
    updatePerson(person: $person) { 
      id
      firstName middleName lastName
      email emailOptIn phone phoneOptIn
      defaultBuyerId
    }
  }`;